import TruckHire from "assets/images/Aberton-truck-hire.jpg";
import TrailerMaintenence from "assets/images/Trailer-Maintenance-banner.jpg";
import Breakdowns from "assets/images/HGVMaintenance.png";
import VehicleDiagonistic from "assets/images/HGV-Maintenance-banner.jpg";
import VehicleMOT from "assets/images/Vehicle-MOT.jpg";
import BrakeTest from "assets/images/break-test.jpg";
import ComprehensiveInspection from "assets/images/Comprehensive-Inspection.jpg";
import Recovery from "assets/images/BREAKDOWN-RECOVERY.png";
import ComprehensiveInspectionIng from "assets/images/Comprehensive-Inspection-02.jpg";
import BreakTestIng from "assets/images/break-test-01.jpg";
import VehicleMOTIng from "assets/images/hgvmechanic.seesite.biz_-_633312291.jpg";
import TrailerMaintenenceIng from "assets/images/Breakdowns-01.jpg";

import TruckHireIng from "assets/images/hgvmechanic.seesite.biz_-_400979477.jpg";
export const Megadropdown = [
  {
    title: "Truck Hire",
    route: "truck-hire",
    bg_image: TruckHire,
    heading: "MOBILE MAINTENANCE ",
    heading_descriptoin:
      "Aberton Group has been trading for over 10 years. we offer a fully mobile maintenance for HGV’s, trailers, light commercial vehicles and more.",
    description: "HGV MAINTENANCE",
    description_image: Recovery,
    title1: "Truck Hire",
    textline1: "",
    textline2: "We offer long and short term hire on or trucks.",
    textline3: "6 Weekly inspection at or state of art Garage.",
    textline4: "Tyres and road tax paid.",
    textline5: "44t MAN euro 6 trucks all ready for London zone (DVS).",
    textline6:
      "Wet gear on some trucks for the use on tanker and tipper trailers.",
    textline7: "Also, we have 7.5t Curtainsider with tail lift.",
    image1: TruckHireIng,
  },
  {
    title: "Trailer Maintenance",
    route: "trailer-maintenance",
    bg_image: TrailerMaintenence,
    heading: "MOBILE MAINTENANCE",
    heading_descriptoin:
      "Aberton Group has been trading for over 10 years. we offer a fully mobile maintenance for HGV’s, trailers, light commercial vehicles and more.",
    description: "",
    description_image: Recovery,
    title1: "TRAILER  MAINTENANCE",
    textline1: "",
    textline2:
      "Being situated in Lancashire we are well placed to provide trailer servicing to a community that relies on trailers for so many applications, from horse boxes to car trailers, from big to small.",
    textline3:
      "With mechanics who have worked on Lancashire for many years we have plenty of experience in keeping your trailer at its best, and we’ve gotten pretty good at it.",
    textline4:
      "With our competitive charges and quick turnaround you’ll not be disappointed.",
    textline5:
      "We will take on any trailer, from general purpose box trailers to artic trailers, our mechanics will take them.",
    textline6: "Service includes as standard",
    bullet:
      "<ul><li>Brakes</li><li>Lights</li><li>Tow Hitch</li> <li>Jockey Wheel</li> <li>Tires</li> <li>General Condition</li></ul>",
    image1: TrailerMaintenenceIng,
  },
  {
    title: "Breakdowns",
    route: "breakdowns",
    bg_image: Breakdowns,
    heading: "MOBILE MAINTENANCE ",
    heading_descriptoin:
      "Aberton Group has been trading for over 10 years. we offer a fully mobile maintenance for HGV’s, trailers, light commercial vehicles and more.",
    description: "",
    description_image: Recovery,
    title1: "BREAKDOWNS",
    textline1: "",
    textline2:
      "We also provide a breakdown service with fast response vehicles plus a vehicle recovery service with call out day and night thus providing the service you need to get you back on the road quickly.",
    textline3:
      "We have experience in all aspects of maintaining and supporting Commercial Vehicles of all types regardless of size and manufacturer.",
    textline4:
      "We work hard to ensure that downtime is minimised, both whilst we have your vehicle and by enhancing its future reliability.",
    textline5:
      "You are advised to pre-book, however, you are also welcome to call in to discuss your requirements.",
    textline6:
      "We also provide a breakdown service with fast response vehicles plus a vehicle recovery service with call out day and night thus providing the service you need to get you back on the road quickly.",
    textline7:
      "We have experience in all aspects of maintaining and supporting Commercial Vehicles of all types regardless of size and manufacturer.",
    textline8:
      "We work hard to ensure that downtime is minimised, both whilst we have your vehicle and by enhancing its future reliability. You are advised to pre-book, however, you are also welcome to call in to discuss your requirements.",
    image1: BreakTestIng,
  },
  {
    title: "Vehicle Diagonistic",
    route: "vehicle-diagonistic",
    bg_image: VehicleDiagonistic,
    heading: "MOBILE MAINTENANCE ",
    heading_descriptoin:
      "Aberton Group has been trading for over 10 years. we offer a fully mobile maintenance for HGV’s, trailers, light commercial vehicles and more.",
    description: "",
    description_image: Recovery,
    title1: "VEHICLE DIAGNOSTICS",
    textline1: "",
    textline2:
      "We have the most up-to-date and advanced diagnostic equipment, capable of reading all makes of commercial vehicle from small vans to trucks and trailers. We can also carry out most auto electrical work as required.",
    textline3: "",
    textline4: "",
    textline5: "",
    textline6: "",
    textline7: "",
    image1: VehicleMOTIng,
  },
  {
    title: "Vehicle MOT",
    route: "vehicle-mot",
    bg_image: VehicleMOT,
    heading: "MOBILE MAINTENANCE ",
    heading_descriptoin:
      "Aberton Group has been trading for over 10 years. we offer a fully mobile maintenance for HGV’s, trailers, light commercial vehicles and more.",
    description: "",
    description_image: Recovery,
    title1: "VEHICLE MOT",
    textline1: " ",
    textline2:
      "We carry out full M.O.T preparation on your commercial vehicle including steam cleans and presentation of vehicles for M.O.T. We can advise you of any work that may be required to your vehicle in order to pass M.O.T inspection.",
    textline3: "",
    textline4: "",
    textline5: "",
    textline6: "",
    textline7: "",
    image1: VehicleMOTIng,
  },
  {
    title: "Brake Test",
    route: "brake-test",
    bg_image: BrakeTest,
    heading: "MOBILE MAINTENANCE ",
    heading_descriptoin:
      "Aberton Group has been trading for over 10 years. we offer a fully mobile maintenance for HGV’s, trailers, light commercial vehicles and more.",
    description: "",
    description_image: Recovery,
    title1: "Break Test",
    textline1: "",
    textline2:
      "We also provide a breakdown service with fast response vehicles plus a vehicle recovery service with call out day and night thus providing the service you need to get you back on the road quickly",
    textline3:
      "We have experience in all aspects of maintaining and supporting Commercial Vehicles of all types regardless of size and manufacturer.",
    textline4:
      "We work hard to ensure that downtime is minimised, both whilst we have your vehicle and by enhancing its future reliability.",
    textline5:
      "You are advised to pre-book, however, you are also welcome to call in to discuss your requirements.",
    textline6:
      "We also provide a breakdown service with fast response vehicles plus a vehicle recovery service with call out day and night thus providing the service you need to get you back on the road quickly. We have experience in all aspects of maintaining and supporting Commercial Vehicles of all types regardless of size and manufacturer.",
    textline7:
      "We work hard to ensure that downtime is minimised, both whilst we have your vehicle and by enhancing its future reliability. You are advised to pre-book, however, you are also welcome to call in to discuss your requirements.",
    image1: BreakTestIng,
  },
  {
    title: "Comprehensive Inspection",
    route: "comprehensive-inspection",
    bg_image: ComprehensiveInspection,
    heading: "MOBILE MAINTENANCE ",
    heading_descriptoin:
      "Aberton Group has been trading for over 10 years. we offer a fully mobile maintenance for HGV’s, trailers, light commercial vehicles and more.",
    description: "",
    description_image: Recovery,
    title1: "Comprehensive Inspection",
    textline1: "",
    textline2:
      "At Aberton Ltd, preventive maintenance inspections cover more ground than just pressing issues.  Aberton Ltd,  goes above and beyond by providing a variety of thorough inspection services that are suited to the unique requirements of various vehicle ownersThey are a thorough assessment of the general condition of your vehicle. ",
    textline3:
      "With a comprehensive approach,Aberton Ltd detects possible problems before they become serious, from fluid levels to engine components. By being proactive, you can prolong the life of your vehicle and ensure safety.",
    textline4: "",
    textline5: "",
    textline6: "",
    textline7: "",
    image1: ComprehensiveInspectionIng,
  },
];

export const keyword_searching = [
  {
    id: 1,
    route: "/",
    title: "Home",
  },
  {
    id: 2,
    route: "hgv-maintenance",
    title: "HGV Maintenance",
  },
  {
    id: 3,
    route: "services/trailer-maintenance",
    title: "Trailer Maintenance",
  },
  {
    id: 4,
    route: "services/breakdowns",
    title: "Breakdown",
  },
  {
    id: 5,
    route: "services/vehicle-diagonistic",
    title: "Vehicle Diagonistic",
  },
  {
    id: 6,
    route: "services/vehicle-mot",
    title: "Vehicle MOT",
  },
  {
    id: 7,
    route: "services/brake-test",
    title: "Brake Test",
  },
  {
    id: 8,
    route: "services/comprehensive-Inspection",
    title: "Comprehensive Inspection",
  },
  {
    id: 9,
    route: "contact",
    title: "Contact",
  },
  {
    id: 10,
    route: "services",
    title: "Services",
  },
  {
    id: 11,
    route: "services/truck-hire",
    title: "Truck Hire",
  },
];
