import React from "react";
import PropTypes from "prop-types";
import "css/component.css";
const MegaDropdown = ({
  data,
  position,
  width,
  left,
  right,
  top,
  bottom,
  background,
  display,
  itemKey,
  onClick,
}) => {
  return (
    <div>
      <div
        className="py-3"
        style={{
          position: position,
          width: width,
          left: left,
          right: right,
          top: top,
          bottom: bottom,
          background: background || "white",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          display: display,
          zIndex: 999,
          borderRadius: "10px",
          textAlign: "start",
          padding: "0px 20px"
        }}
      >
        {data?.map((item, index) => (
          <p
            className="dropdown-text"
            key={index}
            style={{ color: "black", padding: "5px" ,cursor:'pointer', margin:"3px"}}
            onClick={() => onClick(item)}
          >
            {item[itemKey]}
          </p>
        ))}
      </div>
    </div>
  );
};
MegaDropdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  position: PropTypes.string,
  width: PropTypes.string,
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  background: PropTypes.string,
  display: PropTypes.string,
  itemKey: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
export default MegaDropdown;
