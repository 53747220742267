import React from "react";
import Footer from "components/footer/footer";
import InputField from "components/Inputs";
import "css/contact.css";
import { Send_Mail } from "Netwroking";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import useMobile from "customHooks";
import { Helmet } from "react-helmet";
import WELCOMEing from "assets/images/welcome-toimg.png";
import WELCOMEing123 from "assets/images/hgvmechanic.seesite.biz_-_299162719.png";
import HIREATRUCKTODAYimg from "assets/images/HIRE-A-TRUCK-TODAY.png";
const Contact = () => {
  const [contacHandler, setContactHandler] = useState({
    name: "",
    mail: "",
    message: "",
    isError: false,
    isSpinner: false,
  });
  const { isTabletOrMobile } = useMobile();

  const onInputHandleChange = (val, key) => {
    setContactHandler({
      ...contacHandler,
      [key]: val,
    });
  };

  const onClickFunction = async () => {
    if (contacHandler?.name == "" || contacHandler?.mail == "") {
      setContactHandler({ ...contacHandler, isError: true });
    } else {
      const temp_payload = {
        name: contacHandler?.name,
        mail: contacHandler?.mail,
        message: contacHandler?.message,
        form_name: "Aberton:Contact Us Form",
        status: 8,
      };
      try {
        setContactHandler({ ...contacHandler, isSpinner: true });
        await Send_Mail(temp_payload);
        setContactHandler((prevValues) => ({
          ...prevValues,
          name: "",
          mail: "",
          message: "",
          isSpinner: false,
          isError: false,
        }));
      } catch {
        setContactHandler({ ...contacHandler, isSpinner: false });
        window.alert("Email Failed");
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>Contact - Aberton</title>
        <meta property="og:title" content="Contact" />
        <meta property="og:description" content="Established for many years, we offer maintenance and servicing which can be carried out as a one-off or on a predetermined basis depending on mileage or vehicle use." />
        <meta property="og:image" content={{ HIREATRUCKTODAYimg }} />
        {/* <meta property="og:url" content="/hgv-maintenance"/> */}
      </Helmet>
      <div
        className="section introSecCONTACT"
        style={{ height: isTabletOrMobile && "auto" }}
      >
        <div className="container">
          <div className="row ">
            <div className="col-md-12">
              <h1>Contact</h1>
              <hr className="divider" style={{ margin: "0 auto", height: "3px"  }} />
            </div>
          </div>
        </div>
      </div>
      <div className="introSec1"></div>
      <div className="section thirdHomeSec1" style={{ paddingBottom:"0px"}}>
        <div className=" container">
          <div className="row">
            <div className="col-md-1">
              <div className="Welcomimg">
                <img src={WELCOMEing} alt="WELCOME TO ABERTON GROUP" />
              </div>
            </div>
            <div className="col-md-11">
              <h1>CONTACT ABERTON GROUP</h1>
            </div>
            <hr />
           
          </div>
          <div className="row">
            <div className="col-md-9">
             
            </div>
            <div className="col-md-3">
              
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2376.468637721012!2d-1.3359053874203728!3d53.44220846743072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879769c0a2ecd0f%3A0x9d0542f1509e758f!2s5%20Chesterton%20Rd%2C%20Rotherham%20S65%201SU%2C%20UK!5e0!3m2!1sen!2s!4v1689754933528!5m2!1sen!2s"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
        <div style={{ textAlign:"start" }}>
        <p>Get in touch with us using the details provided or via the contact form below.</p></div>
      </div>
      <div className="container my-5">
        <div className="row">
        <h1>Contact Form</h1>
          <div className="col-md-6">
            
            <InputField
              value={contacHandler?.name}
              itemKey="name"
              onChange={onInputHandleChange}
              inputType="text"
              placeholder={"Name"}
              isError={contacHandler?.isError && contacHandler?.name == ""}
            />
          </div>
          <div className="col-md-6">
            <InputField
              value={contacHandler?.mail}
              onChange={onInputHandleChange}
              itemKey="mail"
              inputType="mail"
              placeholder={"Mail"}
              isError={contacHandler?.isError && contacHandler?.mail == ""}
            />
          </div>
        </div>
        <textarea
          placeholder="Message"
          className="input_field my-2"
          value={contacHandler?.message}
          onChange={(val) => onInputHandleChange(val.target.value, "message")}
          style={{ height: "200px" }}
        />
        <div className="d-flex justify-content-end">
          <button
            onClick={onClickFunction}
            style={{
              border: "1px solid #2EA3F2",
              background: "white",
              padding: "10px 50px",
            }}
          >
            {contacHandler?.isSpinner ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <p style={{ margin: "0px", fontWeight: "600", fontSize: "20px" }}>
                Submit
              </p>
            )}
          </button>
        </div>
      </div>
      <div
          className="section thirdHomeSec"
          style={{ backgroundColor: "#002b7f!important", padding: "30px" }}
        >
          <div className=" container">
            <div
              className="row"
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <div className="col-md-6">
                <img src={WELCOMEing123} />

              </div>
              <div className="col-md-6">
                <div className="text-box1">
                  <h1>MOBILE MAINTENANCE & REPAIRS</h1>
                  <p>Aberton Group has been trading for over 10 years. we offer a fully mobile maintenance and repair service for HGV’s, trailers, light commercial vehicles and more.</p>
                  <div>
                    <a href="/hgv-maintenance" className="button">
                      HGV MAINTENANCE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </div>
  );
};
export default Contact;
