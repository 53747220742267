import React from "react";
import WELCOMEing from "assets/images/welcome-toimg.png";
import PropTypes from "prop-types";
const IntroSection = ({
  title,
  textline1,
  textline2,
  textline3,
  textline4,
  textline5,
  image1,
}) => {
  return (
    <div>
      <div className="section thirdHomeSec1">
        <div className=" container">
          <div className="row">
            <div className="col-md-1">
              <div className="Welcomimg">
                <img src={WELCOMEing} alt="WELCOME TO ABERTON GROUP" />
              </div>
            </div>
            <div className="col-md-11">
              <h1>{title}</h1>
            </div>
            <hr />
            <p>{textline1}</p>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p>{textline2}</p>
              <p>{textline3}</p>
              <p>{textline4}</p>
              <p>{textline5}</p>
            </div>
            <div className="col-md-4">
              <img
                src={image1}
                alt="WELCOME TO ABERTON GROUP123"
                style={{ width: "-webkit-fill-available" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

IntroSection.propTypes={
  title:PropTypes.string,
  textline1:PropTypes.string,
  textline2:PropTypes.string,
  textline3:PropTypes.string,
  textline4:PropTypes.string,
  textline5:PropTypes.string,
  image1:PropTypes.string,
}

export default IntroSection;
