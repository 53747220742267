import WELCOMEing from "assets/images/welcome-toimg.png";
import Loading from "components/lazyLoader/lazyloader";
import useMobile from "customHooks";
import { Megadropdown } from "json/data";
import React, { lazy, Suspense, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const ImageLazyLoading = lazy(() =>
  image_loader(import("components/lazyLoader/index.jsx"))
);
const ServicesDetail = () => {
  // const { state } = useLocation();
  const param = useParams();
  const { isTabletOrMobile } = useMobile();
  const location = useLocation();
  // const params = new URLSearchParams(location.search);
  const data = Megadropdown.find((item) => item.route == param.item);
  const searched_ref = useRef(null);
  // const searchTerm = params.get("query");
  useEffect(() => {
    // if (searchTerm) {
    //   const regex = new RegExp(`(${searchTerm})`, "gi");
    //   const newContent = searched_ref.current.innerHTML.replace(
    //     regex,
    //     `<mark>$1</mark>`
    //   );
    //   searched_ref.current.innerHTML = newContent;
    // }
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>{data?.title} - Aberton</title>
      </Helmet>
      <div ref={searched_ref}>
        <div className="section py-0 position-relative">
          <Suspense
            fallback={<Loading height={isTabletOrMobile ? "300px" : "500px"} />}
          >
            <ImageLazyLoading src={data.bg_image} />
          </Suspense>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: isTabletOrMobile ? "300px" : "500px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            }}
          ></div>
          <div className="container vertical_center" style={{ zIndex: 2 }}>
            <div className="row">
              <div className="col-md-12">
                <h1
                  style={{
                    color: "#fff",
                  }}
                >
                  {data?.title}
                  <div style={{ textAlign:"center" }}>
                    
                  <hr className="divider" style={{ margin: "0 auto", height: "3px"  }} />
                  </div>
                  
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="introSec1"></div>
        <div className="section thirdHomeSec1">
          <div className=" container">
            <div className="row">
              <div className="col-md-1">
                <div className="Welcomimg">
                  <img src={WELCOMEing} alt="WELCOME TO ABERTON GROUP" />
                </div>
              </div>
              <div className="col-md-11">
                <h1>{data?.title1}</h1>
              </div>
              <hr />
              <p>{data?.textline1}</p>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p>{data?.textline2}</p>
                <p>{data?.textline3}</p>
                <p>{data?.textline4}</p>
                <p>{data?.textline5}</p>
                <p>{data?.textline6}</p>
                <p>{data?.textline7}</p>
                <p>{data?.textline8}</p>
                <div dangerouslySetInnerHTML={{ __html: data.bullet }}></div>
              </div>
              <div className="col-md-4">
                <img
                  src={data?.image1}
                  alt="WELCOME TO ABERTON GROUP123"
                  style={{ width: "-webkit-fill-available" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="section thirdHomeSec"
          style={{ backgroundColor: "#002b7f!important", padding: "30px" }}
        >
          <div className=" container">
            <div
              className="row"
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <div className="col-md-6">
                <img src={data?.description_image} />
              </div>
              <div className="col-md-6">
                <div className="text-box1">
                  <h1>{data?.heading}</h1>
                  <p>{data?.heading_descriptoin}</p>
                  <div>
                    <a href="/hgv-maintenance" className="button">
                      HGV MAINTENANCE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function image_loader(component) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(component);
    }, 2500);
  });
}

export default ServicesDetail;
