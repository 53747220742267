import IntroSection from "components/sections/IntroSection";
import WELCOMEing2 from "assets/images/hgvmechanic.seesite.biz_-_580692294-1.jpg";
import HIREATRUCKTODAYimg from "assets/images/hgvmechanic.seesite.biz_-_299162719.png";
import React from "react";
import Footer from "components/footer/footer";
import Bullets from "components/bullets";
import { Outlet, useParams } from "react-router-dom";
import useMobile from "customHooks";
import { Helmet } from "react-helmet";
const Services = () => {
  const { isTabletOrMobile } = useMobile();
  const title = "SERVICES";
  // const textline2 = "Our commercial vehicle maintenance services include:";
  const data = [
    "Commercial vehicle accessories & parts can be sourced",
    "General commercial vehicle maintenance",
    "MOT testing",
    "Clutches",
    "Batteries",
    "Brakes",
    "Exhausts",
    "Radiators",
    "Wheel alignment",
    "Engine diagnostics",
    "Engine reconditioning",
    "Fuel injection systems",
    "Oil changing",
    "Vehicle inspection",
    "Steering adjustment",
  ];
  const data1 = [
    "Fully mobile mechanics with quick response times",
    "Excellent workshop facilities for all your vehicle maintenance",
    "Trailer maintenance",
    "HGV maintenance",
    "Mot preparations and Mot testing",
    "Diagnostics on all vehicles",
    "Service contracts also available",
    "Breakdown assistance – 24 hours a day",
    "All makes and models of vehicles",
  ];
  const params = useParams();
  return (
    <div>
      <Helmet>
        <title>Services - Aberton</title>
      </Helmet>
      {Object.entries(params).length == 0 ? (
        <>
          <div
            className="section introSecServices"
            style={{ height: isTabletOrMobile && "auto" }}
          >
            <div className="container">
              <div className="row ">
                <div className="col-md-12">
                  <h1>Services</h1>
                  <hr className="divider" style={{ margin: "0 auto", height: "3px"  }} />
                </div>
              </div>
            </div>
          </div>
          <div className="introSec1"></div>
          <IntroSection
            title={title}
            textline2={
              <>
                <h4>Our commercial vehicle maintenance services include:</h4>
                <Bullets data={data} />
                <h4>Our commercial vehicle maintenance services include:</h4>
                <Bullets data={data1} />
              </>
            }
            image1={WELCOMEing2}
          />
          <div
            className="section thirdHomeSec"
            style={{ backgroundColor: "#002b7f!important", padding: "30px" }}
          >
            <div className=" container">
              <div
                className="row"
                style={{ backgroundColor: "#fff", color: "#000" }}
              >
                <div className="col-md-6">
                  <img src={HIREATRUCKTODAYimg} />
                </div>
                <div className="col-md-6">
                  <div className="text-box1">
                    <h1>MOBILE MAINTENANCE </h1>
                    <p>
                      Aberton Group has been trading for over 10 years. we offer
                      a fully mobile maintenance for HGV’s, trailers, light
                      commercial vehicles and more.
                    </p>
                    <div >
                      <a href="/hgv-maintenance" className="button">
                        HGV MAINTENANCE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Outlet />
      )}

      <Footer />
    </div>
  );
};
export default Services;
