import React from "react";
import "Swipers/swiper.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom";
const AutoSliderSwiper = ({ Data,autoplayDelay,autoplay,className,slidesPerView }) => {
  const navigate=useNavigate()
  return (
    <>
      <Swiper
        pagination={{
          type: "fraction",
        }}
        slidesPerView={slidesPerView}
        autoplay={{
          delay: autoplayDelay,
          autoplay: autoplay,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Navigation, Autoplay]}
        className={className}
      >
        {Data?.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={item.src} alt={`Slide ${index}`} />
            <div className="color-overlay"></div>
              <div className="title-overlay animate__animated animate__fadeInUp ">{item.title}</div>
              <div className="text-overlay animate__animated animate__fadeInUp">{item.text}</div>
              <div className="button-overlay animate__animated animate__fadeInUp">
                <button
                  onClick={() => navigate('/services')}
                >
                  Learn More
                </button>
              </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

AutoSliderSwiper.propTypes={
  Data:PropTypes.array,
  autoplayDelay:PropTypes.number,
  autoplay:PropTypes.bool,
  className:PropTypes.string,
  slidesPerView:PropTypes.number
}

export default AutoSliderSwiper;
