import React from "react";
import Footer from "components/footer/footer";
import { Helmet } from "react-helmet";
const Sitemap = () => {
  return (
    <div>
      <>
      <Helmet>
        <title>Sitemap - Aberton</title>
      </Helmet>
        <div className="section introSec">
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <h1>Sitemap</h1>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="introSec1"></div> */}
        <div className="sitemapSec">
          <div className=" container">
            <div className="row">
              <div className="col-md-12">
                <ul>
                  <li><a href="/"> aberton.co.uk</a></li>
                  <li><a href="/hgv-maintenance">aberton.co.uk/hgv-maintenance</a></li>
                  <li><a href="/services">aberton.co.uk/services</a></li>
                  <li><a href="/services/truck-hire">aberton.co.uk/truck-hire</a></li>
                  <li><a href="/services/trailer-maintenence">aberton.co.uk/trailer-maintenence</a></li>
                  <li><a href="/services/breakdowns">aberton.co.uk/breakdowns</a></li>
                  <li><a href="/services/vehicle-diagonistic">aberton.co.uk/vehicle-diagnostics</a></li>
                  <li><a href="/services/">aberton.co.uk/vehicle-mot</a></li>
                  <li><a href="/contact">aberton.co.uk/contact</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
};
export default Sitemap;
