import React from "react";
import AutoSliderSwiper from "Swipers/AutoSlideSwiper";
import { SliderImages } from "script/slider.js";
import HomeSecondSec from "Section/HomeSecondSec";
import HIREATRUCKTODAYimg from "assets/images/HIRE-A-TRUCK-TODAY.png";
import BREAKDOWNRECOVERYing from "assets/images/BREAKDOWN-RECOVERY.png";
import WELCOMEing2 from "assets/images/VV.jpg";
import Footer from "components/footer/footer";
import IntroSection from "components/sections/IntroSection";
import { Helmet } from "react-helmet";
import useMobile from "customHooks";
const Home = () => {
  const {isMobile} =  useMobile();
  const title = "WELCOME TO ABERTON GROUP";
  const textline1 =
    "Do you need an on-site maintenance service for your HGV, trailer or van? Looking for a fully equipped workshop offering a full vehicle maintenance service? Aberton Group has been trading for over 20 years. we offer a fully mobile maintenance and maintenance service for HGVs, trailers, light commercial vehicles and more. We undertake all aspects of maintenance no matter how great or small, we can take care of the issue on-site for you or at our fully equipped workshop.";
  const textline2 =
    "We carry out all types of HGV Maintenance, including welding, preparation and taking for MOT tests.";
  const textline3 =
    "Our inspections include, brake test, headlight check plus adjustment and smoke test. Alternatively, these checks can be carried out separately upon request.";
  const textline4 =
    "We carry out HGV services, HGV Maintenance and HGV contract maintenance for clients throughout Rochdale and the North West.";
  const textline5 =
    "We hope this information gives a brief insight into the range of services we are able to offer on-site. We would be pleased to discuss any specific requirements you may have.";

  return (
    <div>
      <Helmet>
        <title>Aberton</title>
      </Helmet>
      {/* <Header/> */}
      <AutoSliderSwiper
        Data={SliderImages}
        autoplayDelay={3000}
        autoplay={true}
        className="my-swiper"
        slidesPerView={1}
      />

      <HomeSecondSec />

      <div className="section thirdHomeSec1">
        <div className=" container">
          <div className="row">
            <div className="col-md-6">
              <img src={HIREATRUCKTODAYimg} />
            </div>
            <div className="col-md-6">
              <div className="text-box">
                <h1>HIRE A TRUCK TODAY</h1>

                <p>
                  We offer long and short term hire on or trucks. 6 Weekly
                  inspection at our state of art Garage. Hire includes tyres &
                  road tax paid.
                </p>

                <a href="/services/truck-hire" className="button">
                  TRUCK HIRE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section thirdHomeSec">
        <div className=" container">
          <div className="row">
            <div className="col-md-6">
              {isMobile && <div className="col-md-6">
              <img src={BREAKDOWNRECOVERYing} />
            </div>}
              <div className="text-box">
                <h1>BREAKDOWN RECOVERY</h1>

                <p>
                  We also provide a breakdown service with fast response
                  vehicles plus a vehicle recovery service with call out day and
                  night so you can get back on the road quickly.
                </p>

                <a href="/services/breakdowns" className="button">
                  BREAKDOWN RECOVERY
                </a>
              </div>
            </div>
              {!isMobile && <div className="col-md-6">
              <img src={BREAKDOWNRECOVERYing} />
            </div>}
          </div>
        </div>
      </div>
      <IntroSection
        title={title}
        textline1={textline1}
        textline2={textline2}
        textline3={textline3}
        textline4={textline4}
        textline5={textline5}
        image1={WELCOMEing2}
      />
      {/* <div className="section thirdHomeSec1">
        <div className=" container">
          <div className="row">
            <div className="col-md-1">
              <div className="Welcomimg">
                <img src={WELCOMEing} alt="WELCOME TO ABERTON GROUP" />
              </div>
            </div>
            <div className="col-md-11">
              <h1>WELCOME TO ABERTON GROUP</h1>
            </div>
            <hr />
            <p>
              Do you need an on-site maintenance service for your HGV, trailer
              or van? Looking for a fully equipped workshop offering a full
              vehicle maintenance service? Aberton Group has been trading for
              over 20 years. we offer a fully mobile maintenance and maintenance
              service for HGVs, trailers, light commercial vehicles and more. We
              undertake all aspects of maintenance no matter how great or small,
              we can take care of the issue on-site for you or at our fully
              equipped workshop.
            </p>
          </div>
          <div className="row">
            <div className="col-md-9">
              <p>
                We carry out all types of HGV Maintenance, including welding,
                preparation and taking for MOT tests.
              </p>
              <p>
                Our inspections include, brake test, headlight check plus
                adjustment and smoke test. Alternatively, these checks can be
                carried out separately upon request.
              </p>
              <p>
                We carry out HGV services, HGV Maintenance and HGV contract
                maintenance for clients throughout Rochdale and the North West.
              </p>
              <p>
                We hope this information gives a brief insight into the range of
                services we are able to offer on-site. We would be pleased to
                discuss any specific requirements you may have.
              </p>
            </div>
            <div className="col-md-3">
              <img
                src={WELCOMEing2}
                alt="WELCOME TO ABERTON GROUP"
                style={{ width: "-webkit-fill-available" }}
              />
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="section thirdHomeSec"
        style={{ backgroundColor: "#002b7f!important", padding: "30px" }}
      >
        <div className=" container">
          <div
            className="row"
            style={{ backgroundColor: "#fff", color: "#000" }}
          >
            <div className="col-md-6">
              <img src={HIREATRUCKTODAYimg} />
            </div>
            <div className="col-md-6">
              <div className="text-box1">
                <h1>HIRE A TRUCK TODAY</h1>
                <p>
                  We also provide a breakdown service with fast response
                  vehicles plus a vehicle recovery service with call out day and
                  night so you can get back on the road quickly.
                </p>
                
                <a href="/services/truck-hire" className="button">
                TRUCK HIRE
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Home;
