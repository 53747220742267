import React from "react";
import Icon1 from "assets/images/icon1.png";
import Icon2 from "assets/images/icon2.png";
import Icon3 from "assets/images/icon3.png";
import Icon4 from "assets/images/icon4.png";

const HomeSecondSec = () => {
  return (
    <div>
      <div className="section HomeSecondSec">
        <div className=" container1">
          <div className="row">
            <div className="col-md-3 abc">
              <img className="img-fluid" src={Icon1} />
              <h4>HGV Maintenance</h4>
              <p>
                Established for over 6 years, we offer maintenance and servicing
                which can be carried out as a one-off or on a predetermined
                basis depending on mileage or vehicle use.
              </p>
            </div>
            <div className="col-md-3 abc">
              <img className="img-fluid" src={Icon2} />
              <h4>Breakdowns</h4>
              <p>
                We also provide a breakdown service with fast response vehicles
                plus a vehicle recovery service with call out day and night so
                you can get back on the road as quickly as possible
              </p>
            </div>
            <div className="col-md-3 abc">
              <img className="img-fluid" src={Icon3} />
              <h4>Truck Hire</h4>
              <p>
                We offer long & short term hire with 6 weekly inspection at our
                state of art garage. Tyres and road tax paid. Wet gear on some
                trucks for the use on tanker and tipper trailers.
              </p>
            </div>
            <div className="col-md-3 abc1">
              <img className="img-fluid" src={Icon4} />
              <h4>Trailer Maintenance</h4>
              <p>
                Established for over 6 years, we offer maintenance and servicing
                which can be carried out as a one-off or on a predetermined
                basis depending on mileage or vehicle use.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeSecondSec;
