import React from "react";
import PropTypes from "prop-types";
const Bullets = ({ data }) => {
  return (
    <div>
      <ul>
        {data?.map((item, index) => (
          <li key={index} style={{ fontWeight:"bold" }}>{item}</li>
        ))}
      </ul>
    </div>
  );
};
Bullets.propTypes={
  data:PropTypes.arrayOf(PropTypes.string)
}
export default Bullets;