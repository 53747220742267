import React, { useEffect } from "react";
import Logo from "assets/images/ABERTON-Group.png";
import MegaDropdown from "components/Dropdowns";
import { keyword_searching, Megadropdown } from "json/data";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  NavLink,
  useLocation,
  useNavigate,
 
} from "react-router-dom";
import "./navbar.css";
import useMobile from "customHooks";
import Offcanvas from "react-bootstrap/Offcanvas";
const Navbars = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchParams, setSearchParams] = useState(null);
  const [isCollapse, setIsCollapse] = useState(false);
  const { isTabletOrMobile,isBigScreen } = useMobile();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!searchParams) {
      setIsCollapse(false);
    }

return(()=>setIsCollapse(false))    
  }, [location]);
  const OnSearcParams = (event) => {
    setSearchParams(event.target.value);
  };

  const OnGetRoute = () => {
    const temp_Obj = keyword_searching.find((item) =>
      item.title.toLowerCase().match(searchParams.toLowerCase())
    );
    navigate(!temp_Obj ? '*' : temp_Obj?.route);
    setIsCollapse(false);
  };

  const onMouseEnter = () => {
    setDropdownOpen(true);
  };

  const onMouseLeave = () => {
    setDropdownOpen(false);
  };

  const onToggleOpen = () => {
    setIsCollapse(!isCollapse);
  };

  return (
    <div>
      <div className="Topbar">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="row">
                <div
                  className={
                    isTabletOrMobile
                      ? "icons d-flex justify-content-center"
                      : "icons"
                  }
                >
                  <div>
                    <a href="https://www.facebook.com/Aberton.Group" className="bi bi-facebook"></a>
                    <a href="https://www.linkedin.com/company/aberton-group/" className="bi bi-linkedin"></a>
                    <a href="https://x.com/AbertonOfficial" className="bi bi-twitter-x"></a>

                    <a href="https://www.pinterest.co.uk/abertonofficial/" className="bi bi-pinterest"></a>
                    <a href="https://www.quora.com/profile/Aberton" className="bi bi-quora"></a>

                    <a href="mailto:info@aberton.co.uk" className="bi bi-google hide-on-desktop"></a>
                    <a href="tel:01332323989" className="bi bi-telephone-fill hide-on-desktop"></a>
                    
                  </div>
                </div>
              </div>
            </div>

            {!isTabletOrMobile && (
              <>
                <div className="col-md-3"> </div>
                <div className="col-md-3">
                  <div className="icons1">
                    <div>
                      <a href="tel:01332323989" className="bi bi-telephone-fill"> &nbsp; &nbsp;
                        1332 323 989
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icons1">
                    <div>
                      <a href="mailto:info@aberton.co.uk" className="bi bi-envelope-fill">&nbsp; &nbsp;
                        info@aberton.co.uk
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Navbar expand="lg" className="bg-white">
        <Container>
          <Navbar.Brand>
            <div className="nav-logo">
              <NavLink to="/">
                <img className="img-fluid" src={Logo} alt="ABERTON Group" />
              </NavLink>
            </div>
          </Navbar.Brand>
          {isTabletOrMobile && (
            <button
              style={{ background: "none", border: 0 }}
              onClick={onToggleOpen}
            >
              <span>
                {/* <i className="bi bi-list" style={{ width:"25px" }}></i>
                
                */}
                {/* <strong><i className="bi bi-list"></i></strong> */}
                <i className="bi bi-list" style={{     fontSize: "40px" }}></i>

              </span>
            </button>
          )}
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}

          {!isTabletOrMobile && (
            <Nav className="nav-links">
              <NavLink
                to={"/"}
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#fcd116" : "black",
                  };
                }}
              >
                Home
              </NavLink>
              <NavLink
                to={"/hgv-maintenance"}
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#fcd116" : "black",
                  };
                }}
              >
                HGV Maintenance
              </NavLink>
              <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <NavLink
                  to={"/services"}
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#fcd116" : "black",
                    };
                  }}
                >
                  Services
                </NavLink>
                <span
                  className="mx-2 mt-5"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <i className="bi bi-chevron-down"></i>
                </span>
                {dropdownOpen && (
                  <MegaDropdown
                    position={!isTabletOrMobile && "absolute"}
                    data={Megadropdown}
                    width={!isTabletOrMobile && "250px"}
                    right={!isBigScreen && "60px"}
                    display={"block"}
                    itemKey={"title"}
                    onClick={(item) => {
                      navigate(`services/${item?.route}`, { state: item });
                      setDropdownOpen(false);
                    }}
                  />
                )}
              </div>
              <NavLink
                to={"/contact"}
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#fcd116" : "black",
                  };
                }}
              >
                Contact
              </NavLink>
            </Nav>
          )}
        </Container>
      </Navbar>
      <Offcanvas show={isCollapse} onHide={onToggleOpen}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="nav-logo d-flex justify-content-center">
            <NavLink to="/">
              <img className="img-fluid" src={Logo} alt="ABERTON Group" />
            </NavLink>
          </div>
          <Nav className="nav-links d-flex flex-column">
            <NavLink
              to={"/"}
              style={({ isActive }) => {
                return {
                  color: isActive ? "#fcd116" : "black",
                };
              }}
            >
              <span className="mx-2">
                <i className="bi bi-house-door"></i>
              </span>
              Home
            </NavLink>
            <NavLink
              to={"/hgv-maintenance"}
              className={({ isActive }) => {
                return {
                  color: isActive ? "active_nav_button" : "",
                  background: "active-background-url",
                };
              }}
            >
              <span className="mx-2">
                <i className="bi bi-wrench"></i>
              </span>
              HGV Maintenance
            </NavLink>
            <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <NavLink
                to={"/services"}
                className={({ isActive }) => {
                  return {
                    color: isActive ? "active_nav_button" : "black",
                    background: "active-background-url",
                  };
                }}
              >
                <span className="mx-2">
                  <i className="bi bi-tools"></i>
                </span>
                Services
              </NavLink>
              <span
                className="mx-2 mt-5"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <i className="bi bi-chevron-down"></i>
              </span>
              {dropdownOpen && (
                <MegaDropdown
                  position={!isTabletOrMobile && "absolute"}
                  data={Megadropdown}
                  width={!isTabletOrMobile && "195px"}
                  right={"65px"}
                  display={"block"}
                  itemKey={"title"}
                  onClick={(item) => {
                    navigate(`services/${item?.route}`, { state: item });
                    setDropdownOpen(false);
                  }}
                />
              )}
            </div>
            <NavLink
              to={"/contact"}
              className={({ isActive }) => {
                return {
                  color: isActive ? "active_nav_button" : "black",
                };
              }}
            >
              <span className="mx-2">
                <i className="bi bi-person-lines-fill"></i>
              </span>
              Contact
            </NavLink>

            <div>
              <div className="searchBar d-flex flex-row align-items-center justify-content-between">
                <input placeholder="Search" onKeyUp={OnSearcParams} />
                <div
                  style={{
                    background: "#837118",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                  onClick={OnGetRoute}
                >
                  <span className="text-white">
                    <i className="bi bi-search"></i>
                  </span>
                </div>
              </div>
            </div>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Navbars;
