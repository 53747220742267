import React from 'react'
import Navbars from '../navbar/navbar';

const Header = () => {
  return (
    <div>
        <Navbars />
    </div>
  )
}

export default Header;