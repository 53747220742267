import React from "react";
import "./footer.css";
import Logo from "assets/images/ABERTON-Group.png";

const Footer = () => {
  return (
    <div>
      <div
        className="section footer"
        
      >
        <div className=" container">
          <div className="row">
            <div className="col-1">
              <h1>ABOUT</h1>
              <hr className="divider" />
              <div className="footer-logo">
                <img className="img-fluid" src={Logo} />
              </div>
              <h3>Aberton Group</h3>
              <p>
                We carry out HGV/PSV and trailer service and HGV contract
                maintenance throughout UK. We offer long and short term hire of
                commercial vehicles 7.5 to 44tonnes with safety inspections
                included.
              </p>
            </div>
            <div className="col-2">
              <h1>Rotherham</h1>
              <hr className="divider" />
              <div className="address">
                <p>
                  Aberton Rotherham
                  <br />
                  Unit 5<br />
                  Chesterton Rd
                  <br />
                  Rotherham
                  <br />
                  S65 1TE
                </p>
              </div>
              <p style={{ textAlign: "left" }}>
                <strong>Tel:</strong>{" "}
                <span style={{ textDecoration: "underline", color: "#ffffff" }}>
                  <a
                    href="tel:01332 323 989"
                    style={{ color: "#ffffff", textDecoration: "underline" }}
                  >
                    01332 323 989
                  </a>
                </span>
                <br />
                <strong>Email:</strong>{" "}
                <span style={{ textDecoration: "underline", color: "#ffffff" }}>
                  <a
                    href="mailto:info@aberton.co.uk"
                    style={{ color: "#ffffff", textDecoration: "underline" }}
                  >
                    info@aberton.co.uk
                  </a>
                </span>
              </p>
              <div className="icons123">
                <div>
                  <a href="https://www.facebook.com/Aberton.Group" className="bi bi-facebook"></a>
                  <a href="https://www.linkedin.com/company/aberton-group/" className="bi bi-linkedin"></a>
                  <a href="https://x.com/AbertonOfficial" className="bi bi-twitter-x"></a>
                  <a href="https://www.pinterest.co.uk/abertonofficial/" className="bi bi-pinterest"></a>
                  <a href="https://www.quora.com/profile/Aberton" className="bi bi-quora"></a>
                </div>
              </div>
            </div>
            <div className="col-2">
              <h1>London</h1>
              <hr className="divider" />
              <div className="address">
                <p>
                  THS+ Unit 3
                  <br />
                  Cliffside Ind. Estate
                  <br />
                  Askews Farm Lane
                  <br />
                  Grays
                  <br />
                  RM17 5XR
                </p>
              </div>
              <p style={{ textAlign: "left" }}>
                <strong>Tel:</strong>{" "}
                <span style={{ textDecoration: "underline", color: "#ffffff" }}>
                  <a
                    href="tel:08001026261"
                    style={{ color: "#ffffff", textDecoration: "underline" }}
                  >
                    0800 1026261
                  </a>
                </span>
              </p>
            </div>
            <div className="col-2">
              <h1>Leicester</h1>
              <hr className="divider" />
              <div className="address">
                <p>
                  1 Ashby Rd
                  <br />
                  Lutterworth
                  <br />
                  Leicester
                  <br />
                  LE17 5DN
                </p>
              </div>
            </div>
            <div className="col-3">
              <h1>FIND US</h1>
              <hr className="divider" />
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4969.706164914373!2d0.306935!3d51.479211!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8b7183aaab77b%3A0xab3e51ef42419d4b!2sTHS%2B%20Ltd!5e0!3m2!1sen!2sus!4v1719230334661!5m2!1sen!2sus"
                width="100%"
                height="350px"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="footerbar">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <p style={{ textAlign: "center" }}>
                © COPYRIGHT ABERTON GROUP 2024. ALL RIGHTS RESERVED
                <br />
                <span style={{ color: "#ffffff" }}>
                  <a href="/" style={{ color: "#ffffff" }}>
                    HOME
                  </a>{" "}
                  |{" "}
                  <a href="/hgv-maintenance" style={{ color: "#ffffff" }}>
                    HGV MAINTENANCE
                  </a>{" "}
                  |{" "}
                  <a href="/services" style={{ color: "#ffffff" }}>
                    SERVICES
                  </a>{" "}
                  |{" "}
                  <a href="/contact" style={{ color: "#ffffff" }}>
                    CONTACT
                  </a>{" "}
                  |{" "}
                  <a href="/sitemap" style={{ color: "#ffffff" }}>
                    SITEMAP
                  </a>
                </span>
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
