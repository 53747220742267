import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
// import BrakeTest from './pages/BrakeTest';
// import Breakdowns from './pages/Breakdowns';
import ComprehensiveInspection from './pages/ComprehensiveInspection';
import Contact from './pages/Contact';
import Header from './components/header/header';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '@fontsource/montserrat';
import HGVMaintenance from 'pages/HGVMaintenance';
import Services from 'pages/Services';
import ServicesDetail from 'pages/ServicesDetail';
// import { Helmet } from 'react-helmet';
import 'animate.css';
import Sitemap from 'pages/Sitemap';
import PageNotFound from 'pages/404';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
function App() {
  return (
    <div className="App">
     <Router>
        <Header/> 
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/hgv-maintenance' element={<HGVMaintenance/>}/>
          <Route path='/services' element={<Services/>}>
          <Route path=':item' element={<ServicesDetail/>}/>
          </Route>
          {/* <Route path='/brake-test' element={<BrakeTest/>}/> */}
          {/* <Route path='/break-downs' element={<Breakdowns/>}/> */}
          <Route path='/comprehensive-inspection' element={<ComprehensiveInspection/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/sitemap' element={<Sitemap/>}/>
          <Route path="*" element={<PageNotFound title="404" />} />
          
        </Routes>
      </Router>
      <ScrollToTopButton/>
    </div>
  );
}  

export default App;