import axios from "axios";

export const Send_Mail = async (data) => {
  const request = {
    url: "https://downloader.techvezoto.online/api/send-email",
    headers: {
      "Content-Type": "application/json",
    },
    method:'POST',
    data: JSON.stringify(data),
  };
  return await axios.request(request);
};
