import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PropTypes from "prop-types";
const Loading = ({ height }) => {
  return (
    <div>
      <Skeleton height={height} />
    </div>
  );
};
Loading.propTypes = {
  height: PropTypes.string,
};
export default Loading;
