import IntroSection from "components/sections/IntroSection";
import WELCOMEing2 from "assets/images/hgvmechanic.seesite.biz_-_227931310.jpg";
import HIREATRUCKTODAYimg from "assets/images/HIRE-A-TRUCK-TODAY.png";
import React from "react";
import Footer from "components/footer/footer";
import useMobile from "customHooks";
import { Helmet } from "react-helmet";
const HGVMaintenance = () => {
  const { isTabletOrMobile } = useMobile();

  const title = "HGV MAINTENANCE";
  const textline2 =
    "Established for many years, we offer maintenance and servicing which can be carried out as a one-off or on a predetermined basis depending on mileage or vehicle use.";
  const textline3 =
    "Having offered fleet maintenance services for large and small firms across the UK, our experienced mechanics can fix just about every aspect of your commercial vehicle, always offering a prompt, professional and efficient service.";
  const textline4 =
    "Our work is completed to a very high standard. We use premium quality parts and the latest vehicle technologies to ensure that diagnostics are accurate and maintenance not only solve the problem, but provide long term improvements to the vehicle.";

  return (
    <div>
      <Helmet>
        <title>HGV Maintenance - Aberton</title>
        
      </Helmet>
      <div
        className="section introSec"
        style={{ height: isTabletOrMobile && "auto" }}
      >
        <div className="container">
          <div className="row ">
            <div className="col-md-12">
              <h1>HGV Maintenance</h1>
              <hr className="divider" style={{ margin: "0 auto", height: "3px"  }} />
            </div>
          </div>
        </div>
      </div>
      <div className="introSec1"></div>
      <IntroSection
        title={title}
        textline2={textline2}
        textline3={textline3}
        textline4={textline4}
        image1={WELCOMEing2}
      />
      <div
        className="section thirdHomeSec"
        style={{ backgroundColor: "#002b7f!important", padding: "30px" }}
      >
        <div className=" container">
          <div
            className="row"
            style={{ backgroundColor: "#fff", color: "#000" }}
          >
            <div className="col-md-6">
              <img src={HIREATRUCKTODAYimg} />
            </div>
            <div className="col-md-6">
              <div className="text-box1">
                <h1>MOBILE MAINTENANCE</h1>
                <p>
                  Aberton Group has been trading for over 10 years. we offer a
                  fully mobile maintenance for HGV’s, trailers, light commercial
                  vehicles and more.
                </p>
                <div>
                  <a href="/hgv-maintenance" className="button">
                    HGV MAINTENANCE
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default HGVMaintenance;
