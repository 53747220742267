import React from "react";
import PropTypes from "prop-types";
const InputField = ({
  onChange,
  itemKey,
  value,
  inputType,
  placeholder,
  isError,
}) => {
  return (
    <div>
      <input
        placeholder={placeholder}
        className="input_field my-2"
        value={value}
        type={inputType}
        onChange={(val) => onChange(val.target.value,itemKey)}
      />
      {isError && (
        <p style={{ color: "red", fontSize: "11px" }}>
          Please Enter this Field
        </p>
      )}
    </div>
  );
};
InputField.propTypes = {
  onChange: PropTypes.func,
  itemKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputType: PropTypes.string,
  placeholder: PropTypes.string,
  isError: PropTypes.bool,
};
export default InputField;
