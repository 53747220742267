
import SliderImage1 from "assets/images/Aberton-Home-1.jpg";
import SliderImage2 from "assets/images/Aberton-Home-2.jpg";
import SliderImage3 from "assets/images/Aberton-home-3.jpg";

export const SliderImages = [
    { src: SliderImage1, title: "Welcome to Aberton Group", text: "We carry out HGV services, HGV Maintenance and HGV contract maintenance for clients throughout UK." },
    { src: SliderImage2, title: "Contact  Aberton Group", text: "Contact Aberton Group today on 1332 323 989 or email us on info@aberton.co.uk" },
    { src: SliderImage3, title: "Aberton Truck Hire", text: "We offer long and short term hire on our trucks with 6 Weekly inspection at or state of art garage, call us today."  },
];
